import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import { Button, Card, Modal, message, Form } from "antd";
import { EditOutlined, InboxOutlined } from "@ant-design/icons";
import Dragger from "antd/es/upload/Dragger";
import "./Employee.css";

const Documents = () => {
	const [aadharImage, setAadharImg] = useState(null);
	const [panImage, setPanImage] = useState(null);
	const [offerLetter, setOfferLetter] = useState(null);
	const [pfdeclaration, setpfdeclaration] = useState(null);
	const [file, setFile] = useState(null);
	const [type, setType] = useState(null);
	const [imageEditMode, setImageEditMode] = useState(false);
	const { token, apiurl } = useAuth();
	const [loading, setLoading] = useState(false);
	const [form] = Form.useForm();

	useEffect(() => {
		fetchImages();
	}, []);
	const fetchImages = async () => {
		try {
			const response = await fetch(`${apiurl}/handleimages/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setAadharImg(data.data.aadhar_image);
				setPanImage(data.data.pan_image);
				setOfferLetter(data.data.offer_letter);
				setpfdeclaration(data.data.pf_declaration);
			} else {
				const data = await response.json();
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error fetching images");
		}
	};
	const updateImages = async (type) => {
		setLoading(true);
		if (file) {
			const formData = new FormData();
			formData.append("type", type);
			formData.append("image", file);

			console.log(formData);
			try {
				const response = await fetch(`${apiurl}/handleimages/`, {
					method: "PUT",
					headers: {
						Authorization: `Bearer ${token}`,
					},
					body: formData,
				});
				if (response.ok) {
					const data = await response.json();
					message.success(data.message);
					setImageEditMode(false);
					fetchImages();
					setLoading(false);
				} else {
					const data = await response.json();
					message.error(data.error);
					setLoading(false);
				}
			} catch (error) {
				message.error("Error fetching images");
				setLoading(false);
			}
		} else {
			setLoading(false);
			message.warning("Please select a file to upload.");
		}
	};

	const handleFileChange = (info) => {
		const { status, originFileObj } = info.file;
		if (status === "done") {
			setFile(originFileObj);
		} else if (status === "error") {
			message.error(info?.file?.error?.message);
		}
	};

	const customRequest = ({ file, onSuccess }) => {
		setFile(file);
		onSuccess("ok");
	};
	return (
		<div className="documents-main">
			<Card bordered={false} title="Documents">
				<div className="group">
					<Card
						className="document-item"
						title="PAN Card Image"
						style={{ marginBottom: 16 }}
						extra={
							!panImage && (
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										setType("pan");
										setImageEditMode(true);
									}}
								/>
							)
						}>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${apiurl}${panImage}`}>
							<img
								height={200}
								width={200}
								src={`${apiurl}${panImage}`}
								alt="pan card"
							/>
						</a>
					</Card>
					<Card
						className="document-item"
						title="Aadhaar Card Image"
						extra={
							!aadharImage && (
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										setType("aadhaar");
										setImageEditMode(true);
									}}
								/>
							)
						}>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${apiurl}${aadharImage}`}>
							<img
								height={200}
								width={200}
								src={`${apiurl}${aadharImage}`}
								alt="aadhaar card"
							/>
						</a>
					</Card>
					<Card
						className="document-item"
						title="Offer Letter"
						extra={
							!offerLetter && (
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										setType("offerLetter");
										setImageEditMode(true);
									}}
								/>
							)
						}>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${apiurl}${offerLetter}`}>
							<img
								height={200}
								width={200}
								src={`${apiurl}${offerLetter}`}
								alt="offer Letter"
							/>
						</a>
					</Card>
					<Card
						className="document-item"
						title="PF Declaration"
						extra={
							!pfdeclaration && (
								<Button
									icon={<EditOutlined />}
									onClick={() => {
										setType("pfdeclaration");
										setImageEditMode(true);
									}}
								/>
							)
						}>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${apiurl}${pfdeclaration}`}>
							<img
								height={200}
								width={200}
								src={`${apiurl}${pfdeclaration}`}
								alt="PF Declaration"
							/>
						</a>
					</Card>
				</div>
			</Card>

			<Modal
				open={imageEditMode}
				destroyOnClose
				title="Edit Image"
				onCancel={() => {
					setImageEditMode(false);
					fetchImages();
				}}
				footer={[
					<Button key="cancel" onClick={() => setImageEditMode(false)}>
						Cancel
					</Button>,
					<Button
						key="submit"
						type="primary"
						loading={loading}
						onClick={() => form.submit()}>
						Submit
					</Button>,
				]}>
				<Form form={form} layout="vertical" onFinish={() => updateImages(type)}>
					{type === "profile" && (
						<Form.Item label="Profile Image">
							<Dragger
								className="image-upload"
								accept=".png,.jpg,.jpeg"
								maxCount={1}
								customRequest={customRequest}
								onChange={handleFileChange}>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Click or drag image to this area to upload
								</p>
								<p className="ant-upload-hint">Upload only image files</p>
							</Dragger>
						</Form.Item>
					)}
					{type === "pan" && (
						<Form.Item label="PAN Card Image">
							<Dragger
								className="image-upload"
								accept=".png,.jpg,.jpeg"
								maxCount={1}
								customRequest={customRequest}
								onChange={handleFileChange}>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Click or drag image to this area to upload
								</p>
								<p className="ant-upload-hint">Upload only image files</p>
							</Dragger>
						</Form.Item>
					)}
					{type === "offerLetter" && (
						<Form.Item label="Offer Letter">
							<Dragger
								className="image-upload"
								accept=".png,.jpg,.jpeg"
								maxCount={1}
								customRequest={customRequest}
								onChange={handleFileChange}>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Click or drag image to this area to upload
								</p>
								<p className="ant-upload-hint">Upload only image files</p>
							</Dragger>
						</Form.Item>
					)}
					{type === "pfdeclaration" && (
						<Form.Item label="PF Declaration">
							<Dragger
								className="image-upload"
								accept=".png,.jpg,.jpeg"
								maxCount={1}
								customRequest={customRequest}
								onChange={handleFileChange}>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Click or drag image to this area to upload
								</p>
								<p className="ant-upload-hint">Upload only image files</p>
							</Dragger>
						</Form.Item>
					)}
					{type === "aadhaar" && (
						<Form.Item label="Aadhar Card Image">
							<Dragger
								className="image-upload"
								accept=".png,.jpg,.jpeg"
								maxCount={1}
								customRequest={customRequest}
								onChange={handleFileChange}>
								<p className="ant-upload-drag-icon">
									<InboxOutlined />
								</p>
								<p className="ant-upload-text">
									Click or drag image to this area to upload
								</p>
								<p className="ant-upload-hint">Upload only image files</p>
							</Dragger>
						</Form.Item>
					)}
				</Form>
			</Modal>
		</div>
	);
};

export default Documents;
