import React, { useEffect, useRef, useState } from "react";
import Main from "../Homepages/Layouts/Main";
import { Button, Select, Input, message } from "antd";
import { useAuth } from "../utils/useAuth";
import "./Payroll.css";
import Loader from "../Loader/Loader";

const { Option } = Select;

const UserPayroll = () => {
	const [month, setMonth] = useState("");
	const contentRef = useRef(null);
	const [year, setYear] = useState("");
	const [payslipHtml, setPayslipHtml] = useState("");
	const [showPayslipModal, setShowPayslipModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const { apiurl, token } = useAuth();
	const [yearMonthData, setYearMonthData] = useState({});

	useEffect(() => {
		const fetchYearMonthData = async () => {
			try {
				const childId = sessionStorage.getItem("selectedChildId");
				const response = await fetch(`${apiurl}/get-avail-years-months/`, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
					body: JSON.stringify({ id: childId }),
				});
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				const data = await response.json();
				setYearMonthData(data.data);
			} catch (error) {
				console.error("Error fetching year and month data:", error);
			}
		};

		fetchYearMonthData();
	}, [apiurl, token]);


	const generatePayslip = async (values) => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/payslip/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});
			const data = await response.json();
			setLoading(false);
			if (response.ok) {
				setPayslipHtml(data.payslip_html);
				setShowPayslipModal(true);
			} else {
				message.error("Payslip not found for the given month and year");
			}
		} catch {
			setLoading(false);
			message.error("Payslip not found for the given month and year");
		}
	};

	const handleDownloadPDF = async () => {
		window.print();
	};

	const handleSendEmail = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/emailpayslip/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({ payslip_html: payslipHtml }),
			});
			const data = await response.json();
			setLoading(false);
			if (response.ok) {
				message.success("Payslip sent successfully to your email");
			} else {
				message.error("Please try again after some time")
			}
		} catch {
			setLoading(false);
			message.error("Please try again after some time");
		}
	};

	const handleSubmit = () => {
		const values = {
			month: parseInt(month),
			year: parseInt(year),
		};
		generatePayslip(values);
	};

	const onHidePayslipModal = () => {
		setShowPayslipModal(false);
	};
	if (loading) {
		return <Loader />;
	}

	const handleYearChange = (value) => {
		setYear(value);
	};

	const handleMonthChange = (value) => {
		setMonth(value);
	};

	const yearOptions = Object.keys(yearMonthData);

	let monthOptions = [];
	if (yearMonthData[year]) {
		monthOptions = yearMonthData[year];
	}
	return (
		<Main>
			<div className="payroll-user-main">
				{!showPayslipModal && (
					<div className="selections">
						<h1>Download your Payslip here </h1>
						<label>Select Year</label>
						<Select
							style={{ width: 120 }}
							value={year}
							placeholder="Select Year"
							onChange={handleYearChange}>
							{yearOptions.map((y) => (
								<Option key={y} value={y}>
									{y}
								</Option>
							))}
						</Select>
						<label>Select Month</label>
						<Select
							style={{ width: 120 }}
							value={month}
							placeholder="Select Month"
							onChange={handleMonthChange}>
							{monthOptions.map((m) => (
								<Option key={m} value={m}>
									{new Date(0, m - 1).toLocaleString("default", {
										month: "long",
									})}
								</Option>
							))}
						</Select>
						<Button type="primary" onClick={handleSubmit}>
							Submit
						</Button>
					</div>
				)}
				{showPayslipModal && (
					<>
						<div
							ref={contentRef}
							className="payslip-html"
							id="payslipcontent"
							dangerouslySetInnerHTML={{ __html: payslipHtml }}
						/>
						<div className="pay-btns">
							<Button type="primary" onClick={handleDownloadPDF}>
								Print/Download
							</Button>
							<Button type="primary" onClick={handleSendEmail}>
								Send Email
							</Button>
							<Button onClick={onHidePayslipModal}>Close</Button>
						</div>
					</>
				)}
			</div>
		</Main>
	);
};

export default UserPayroll;
