import React, { useEffect, useState } from "react";
import { Form, Input, Button, InputNumber, message, Spin, Modal } from "antd";
import { useAuth } from "../utils/useAuth";
import "./Form12BB.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Main from "../Homepages/Layouts/Main";
import Loader from "../Loader/Loader";
import EvidenceForm from "./EvidenceForm";

const Form12BB = () => {
	const [loading, setLoading] = useState(false);
	const [initialLoading, setInitialLoading] = useState(true);
	const [formData, setFormData] = useState(null);
	const { apiurl, token } = useAuth();
	const [htmlContent, setHtmlContent] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);

	const [form] = Form.useForm();

	useEffect(() => {
		const fetchFormData = async () => {
			try {
				const response = await fetch(`${apiurl}/myform12bb/`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (response.ok) {
					const data = await response.json();
					setFormData(data);
					form.setFieldsValue(data);
				} else {
					setFormData(null);
				}
			} catch (error) {
			} finally {
				setInitialLoading(false);
			}
		};

		fetchFormData();
	}, [apiurl, token, form]);

	useEffect(() => {
		if (formData) {
			handleDownload(formData.id);
		}
	}, [formData]);

	const handleDownload = async (formId) => {
		try {
			const response = await fetch(`${apiurl}/form-12bb/download/${formId}/`);
			if (response.ok) {
				const htmlContent = await response.text();
				setHtmlContent(htmlContent);
			}
		} catch (error) {
			console.error("Error fetching HTML content:", error);
		}
	};

	const onFinish = async (values) => {
		setLoading(true);

		try {
			const response = await fetch(`${apiurl}/form12bb/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(values),
			});

			const result = await response.json();

			if (response.ok) {
				message.success("Form12BB created/updated successfully!");
				setFormData(result);
				form.resetFields();
				form.setFieldsValue(result);
			} else {
				message.error(`Error: ${result.error || "Something went wrong!"}`);
			}
		} catch (error) {
			message.error("Failed to submit the form. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	const onFinishFailed = () => {
		message.error("Please complete the form before submitting.");
	};

	if (initialLoading) {
		return (
			<div className="form12bb-loading">
				<Loader size="large" />
			</div>
		);
	}

	const handleDownloadPDF = async () => {
		window.print();
	};

	const showModal = () => {
		setIsModalVisible(true);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};

	return (
		<Main>
			<div className="form12bb-container">
				{htmlContent ? (
					<>
						<div className="form-12B-form-container">
							<div dangerouslySetInnerHTML={{ __html: htmlContent }} />
						</div>
						<Button
							type="primary"
							onClick={handleDownloadPDF}
							className="print-btn">
							Print/Download
						</Button>
						<Button type="primary" onClick={showModal}>
							Open Evidence Form
						</Button>
						<Modal
							title="Evidence Form"
							open={isModalVisible}
							onCancel={handleCancel}
							footer={null}
						>
							<EvidenceForm formId={formData.id} />
						</Modal>
					</>
				) : (
					<Form
						form={form}
						name="form12bb"
						layout="vertical"
						onFinish={onFinish}
						onFinishFailed={onFinishFailed}
						className="form12bb-form">
						<div className="form12bb-section">
							<h3 className="form12bb-section-title">Personal Details</h3>
							<Form.Item
								label="Employee Name"
								name="employee_name"
								className="form12bb-item"
								rules={[
									{ required: true, message: "Please input Employee name!" },
								]}>
								<Input
									placeholder="Enter employee name"
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Employee Address"
								name="employee_address"
								className="form12bb-item"
								rules={[
									{ required: true, message: "Please input Employee address!" },
								]}>
								<Input.TextArea
									placeholder="Enter employee address"
									className="form12bb-textarea"
								/>
							</Form.Item>

							<Form.Item
								label="PAN"
								name="pan"
								className="form12bb-item"
								rules={[
									{ required: true, message: "Please input PAN!" },
									{ len: 10, message: "PAN should be exactly 10 characters" },
								]}>
								<Input placeholder="Enter PAN" className="form12bb-input" />
							</Form.Item>

							<Form.Item
								label="Financial Year"
								name="financial_year"
								className="form12bb-item"
								rules={[
									{ required: true, message: "Please input financial year!" },
								]}>
								<Input
									placeholder="Enter financial year (e.g., 2023-2024)"
									className="form12bb-input"
								/>
							</Form.Item>
						</div>

						<div className="form12bb-section">
							<h3 className="form12bb-section-title">Rental Details</h3>
							<Form.Item
								label="Rent Paid"
								name="rent_paid"
								className="form12bb-item"
								rules={[
									{ required: true, message: "Please input rent paid!" },
								]}>
								<InputNumber
									style={{ width: "100%" }}
									placeholder="Enter rent paid"
									min={0}
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Landlord Name"
								name="landlord_name"
								className="form12bb-item"
								rules={[
									{ required: true, message: "Please input landlord name!" },
								]}>
								<Input
									placeholder="Enter landlord name"
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Landlord Address"
								name="landlord_address"
								className="form12bb-item"
								rules={[
									{ required: true, message: "Please input landlord address!" },
								]}>
								<Input.TextArea
									placeholder="Enter landlord address"
									className="form12bb-textarea"
								/>
							</Form.Item>

							<Form.Item
								label="Landlord PAN"
								name="landlord_pan"
								className="form12bb-item">
								<Input
									placeholder="Enter landlord PAN (optional)"
									className="form12bb-input"
								/>
							</Form.Item>
						</div>

						<div className="form12bb-section">
							<h3 className="form12bb-section-title">Travel & Interest</h3>
							<Form.Item
								label="Leave Travel Concessions"
								name="leave_travel_concessions"
								className="form12bb-item"
								rules={[
									{
										required: true,
										message: "Please input leave travel concessions!",
									},
								]}>
								<InputNumber
									style={{ width: "100%" }}
									placeholder="Enter leave travel concessions"
									min={0}
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Interest Paid"
								name="interest_paid"
								className="form12bb-item"
								rules={[
									{ required: true, message: "Please input interest paid!" },
								]}>
								<InputNumber
									style={{ width: "100%" }}
									placeholder="Enter interest paid"
									min={0}
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Lender Name"
								name="lender_name"
								className="form12bb-item"
								rules={[
									{ required: true, message: "Please input lender name!" },
								]}>
								<Input
									placeholder="Enter lender name"
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Lender Address"
								name="lender_address"
								className="form12bb-item"
								rules={[
									{ required: true, message: "Please input lender address!" },
								]}>
								<Input.TextArea
									placeholder="Enter lender address"
									className="form12bb-textarea"
								/>
							</Form.Item>

							<Form.Item
								label="Lender PAN"
								name="lender_pan"
								className="form12bb-item">
								<Input
									placeholder="Enter lender PAN (optional)"
									className="form12bb-input"
								/>
							</Form.Item>
						</div>

						<div className="form12bb-section">
							<h3 className="form12bb-section-title">Tax Savings</h3>
							<Form.Item
								label="Section 80C"
								name="section_80c"
								className="form12bb-item">
								<InputNumber
									style={{ width: "100%" }}
									placeholder="Enter amount under Section 80C"
									min={0}
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Section 80CCC"
								name="section_80ccc"
								className="form12bb-item">
								<InputNumber
									style={{ width: "100%" }}
									placeholder="Enter amount under Section 80CCC"
									min={0}
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Section 80CCD"
								name="section_80ccd"
								className="form12bb-item">
								<InputNumber
									style={{ width: "100%" }}
									placeholder="Enter amount under Section 80CCD"
									min={0}
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Section 80D"
								name="section_80d"
								className="form12bb-item">
								<InputNumber
									style={{ width: "100%" }}
									placeholder="Enter amount under Section 80D"
									min={0}
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Section 80E Interest Paid"
								name="section_80e_interest_paid"
								className="form12bb-item">
								<InputNumber
									style={{ width: "100%" }}
									placeholder="Enter amount under Section 80E Interest Paid"
									min={0}
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Section 80G Donation Amount"
								name="section_80g_donation_amount"
								className="form12bb-item">
								<InputNumber
									style={{ width: "100%" }}
									placeholder="Enter amount under Section 80G Donation Amount"
									min={0}
									className="form12bb-input"
								/>
							</Form.Item>

							<Form.Item
								label="Section 80TTA Interest Earned"
								name="section_80tta_interest_earned"
								className="form12bb-item">
								<InputNumber
									style={{ width: "100%" }}
									placeholder="Enter amount under Section 80TTA Interest Earned"
									min={0}
									className="form12bb-input"
								/>
							</Form.Item>
						</div>

						<div className="form12bb-section">
							<Form.Item
								label="Place"
								name="place"
								className="form12bb-item"
								rules={[{ required: true, message: "Please input place!" }]}>
								<Input placeholder="Enter place" className="form12bb-input" />
							</Form.Item>
						</div>

						<Form.Item className="form12bb-button">
							<Button
								type="primary"
								htmlType="submit"
								loading={loading}
								className="form12bb-submit-btn">
								{formData ? "Update Form" : "Submit Form"}
							</Button>
						</Form.Item>
					</Form>
				)}
			</div>
		</Main>
	);
};

export default Form12BB;
