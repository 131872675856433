
import { useAuth } from "../../utils/useAuth";
import {  message,Form,Select,Button,Card} from "antd";
import React, { useEffect, useState ,} from "react";
import {Link, useNavigate} from "react-router-dom";
import Main from "../Layouts/Main";


const EmpInOrg=()=>{
    const { apiurl, token } = useAuth();
    const [month, setMonth] = useState("");
	const [year, setYear] = useState("");
    const navigate= useNavigate();
    const [employees,SetEmployees] = useState(null);
    const { Option } = Select;
    const columns = [
		{
			title: "Name",
			dataIndex: "userName",
			key: "userName",
		},
		{
			title: "Date of Joining",
			dataIndex: "dateOfJoining",
			key: "dateOfJoining",
		},
		{
			title: "Department",
			dataIndex: "department",
			key: "department",
		},
		{
			title: "Designation",
			dataIndex: "designation",
			key: "designation",
			
		},
		{
			title: "Leaves Taken",
			dataIndex: "leaves",
			key: "leaves",
			
		},
        {
			title: "Leave Balance",
			dataIndex: "leavebal",
			key: "leavebal",
			
		},
	];

    
    const fetchData = async ()=>{
      navigate(`/viewemployees/${month}/${year}/`)
    };
    
return(
	<Main>
		
	<div  className="container" >
		<Card>
		<h1>Employee Data</h1>
	 
	 <label>Select Year</label>
				 <Select
				//  value={undefined}
					 placeholder="Select Year"
					 onChange={(value) => setYear(value)}
					 value={year}>
					 <Option value="2023">2023</Option>
					 <Option value="2024">2024</Option>
					 <Option value="2025">2025</Option>
				 </Select>
				 <label>Select Month</label>
				 <Select
					 
					 placeholder="Select Month"
					 onChange={(value) => setMonth(value)}
					 value={month}>
					 <Option value="1">January</Option>
					 <Option value="2">February</Option>
					 <Option value="3">March</Option>
					 <Option value="4">April</Option>
					 <Option value="5">May</Option>
					 <Option value="6">June</Option>
					 <Option value="7">July</Option>
					 <Option value="8">August</Option>
					 <Option value="9">September</Option>
					 <Option value="10">October</Option>
					 <Option value="11">November</Option>
					 <Option value="12">December</Option>
				 </Select>
 
				 
				 <Button type="primary" onClick={fetchData}>
					 View
				 </Button>
				 </Card>
			 </div>

	 </Main>
);


};
export default EmpInOrg;