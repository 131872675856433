import React, { useEffect, useState } from "react";
import { useAuth } from "../../utils/useAuth";
import { Button, Card, Input, Modal, Table, message } from "antd";
import "./Home.css";
import AddEmployee from "../../Employee/AddEmployee";
import BulkUpload from "../../Employee/Bulkupload";
import HeaderComponent from "../../Header/Header";
import { LeftCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const AddEmployeeComponent = () => {
	const [employees, setEmployees] = useState([]);
	const { apiurl, token } = useAuth();
    const navigate = useNavigate();
	const [searchText, setSearchText] = useState("");
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isBulkModalVisible, setIsBulkModalVisible] = useState(false);
	const [selectedEmployee, setSelectedEmployee] = useState(null);
	const [isPopupVisible, setPopupVisible] = useState(false);

	useEffect(() => {
		fetchemployees();
	}, []);

	const fetchemployees = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchemployees/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setEmployees(data.data);
				// setIsSuccess(true);
			} else {
				message.error("Unable to find Employees. Please try again.");
			}
		} catch (error) {
			message.error(error);
		}
	};

	const handleSearch = (e) => {
		const value = e.target.value.toLowerCase();
		setSearchText(value);
	};
	const filteredEmployees = employees.filter(
		(employee) =>
			employee.userName?.toLowerCase().includes(searchText) ||
			employee.department?.toLowerCase().includes(searchText) ||
			employee.designation?.toLowerCase().includes(searchText)
	);

	const handleView = (record) => {
		setSelectedEmployee(record);
		setPopupVisible(true);
	};

	const handleClosePopup = () => {
		setPopupVisible(false);
	};
	const columns = [
		{
			title: "Name",
			dataIndex: "userName",
			key: "userName",
			render: (text) => (text ? text : "-"),
		},
		{
			title: "Department",
			dataIndex: "department",
			key: "department",
			render: (text) => (text ? text : "-"),
		},
		{
			title: "Designation",
			dataIndex: "designation",
			key: "designation",
			render: (text) => (text ? text : "-"),
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: (text) => (text ? text : "-"),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => (text ? text : "-"),
		},
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<Button onClick={() => handleView(record)}>View</Button>
			),
		},
	];
	return (
		<HeaderComponent>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			<div className="add-employee-main">
				<Card
					title="EMPLOYEES"
					extra={
						<div className="display-flex">
							<Button
								onClick={() =>
									navigate(
										`/onboardemployee/EMPLOYEE/${sessionStorage.getItem(
											"selectedChildId"
										)}`
									)
								}>
								Add new
							</Button>{" "}
							<Button onClick={() => setIsBulkModalVisible(true)}>
								Bulk Upload
							</Button>
						</div>
					}>
					<div className="search">
						<Input
							placeholder="Search employees"
							value={searchText}
							onChange={handleSearch}
						/>
					</div>
					<Table
						className="employee-table"
						dataSource={filteredEmployees}
						columns={columns}
						rowKey="id"
						bordered={false}
					/>
				</Card>

				<Modal
					title="Add Employee"
					open={isModalVisible}
					destroyOnClose
					onCancel={() => {
						fetchemployees();
						setIsModalVisible(false);
					}}
					footer={null}>
					<div className="choice-selection">
						<AddEmployee
							role="EMPLOYEE"
							child_id={sessionStorage.getItem(
								"selectedChildId"
							)}></AddEmployee>
					</div>
				</Modal>
				<Modal
					title="Upload file"
					open={isBulkModalVisible}
					onCancel={() => {
						fetchemployees();
						setIsBulkModalVisible(false);
					}}
					footer={null}>
					<div className="choice-selection">
						<BulkUpload></BulkUpload>
					</div>
				</Modal>
				<Modal
					title="Employee Details"
					open={isPopupVisible}
					onCancel={handleClosePopup}
					footer={null}>
					{selectedEmployee && (
						<div>
							<p>Name: {selectedEmployee.userName || "-"}</p>
							<p>Department: {selectedEmployee.department || "-"}</p>
							<p>Designation: {selectedEmployee.designation || "-"}</p>
							<p>Date Of Birth: {selectedEmployee.dateOfBirth || "-"}</p>
							<p>Type: {selectedEmployee.type || "-"}</p>
							<p>Gender: {selectedEmployee.gender || "-"}</p>
							<p>Email: {selectedEmployee.email || "-"}</p>
							<p>Contact Number: {selectedEmployee.phoneNumber || "-"}</p>
							<p>Reported To: {selectedEmployee.reported_to || "-"}</p>
							<p>Date Of Joining: {selectedEmployee.dateOfJoining || "-"}</p>
							<p>Status: {selectedEmployee.status || "-"}</p>
						</div>
					)}
				</Modal>
			</div>
		</HeaderComponent>
	);
};

export default AddEmployeeComponent;
