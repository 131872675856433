import React, { useState, useEffect } from "react";
import { Input, Button, Card, Form, Select, Modal } from "antd";
import { useAuth } from "../../utils/useAuth";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Option } = Select;

const MonthlyDataList = () => {
	const [monthlyData, setMonthlyData] = useState([]);
	const [filteredData, setFilteredData] = useState([]);
	const [yearFilter, setYearFilter] = useState("");
	const [monthFilter, setMonthFilter] = useState("");
	const [editingId, setEditingId] = useState(null);
	const [editForm, setEditForm] = useState({ no_of_working_days: "" });
	const [modalVisible, setModalVisible] = useState(false);

	const { token, apiurl } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await fetch(
					`${apiurl}/monthlydata/?child_id=${sessionStorage.getItem(
						"selectedChildId"
					)}`,
					{
						headers: { Authorization: `Bearer ${token}` },
					}
				);
				if (!response.ok) {
					throw new Error("Failed to fetch data");
				}
				const data = await response.json();
				setMonthlyData(data);
				setFilteredData(data);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [token, apiurl]);

	const handleFilterChange = (value, name) => {
		if (name === "year") {
			setYearFilter(value);
			setMonthFilter(""); 
			filterData(value, "", "year");
		} else if (name === "month") {
			setMonthFilter(value);
			filterData(yearFilter, value, "month");
		}
	};

	const filterData = (year, month, filterType) => {
		let filtered = monthlyData;

		if (filterType === "year" || !monthFilter) {
			filtered = filtered.filter(
				(item) => !year || item.year.toString() === year
			);
		}

		if (filterType === "month" || !yearFilter) {
			filtered = filtered.filter(
				(item) => !month || item.month.toString() === month
			);
		}

		setFilteredData(filtered);
	};

	const handleEditClick = (item) => {
		setEditingId(item.id);
		setEditForm({ no_of_working_days: item.no_of_working_days });
		setModalVisible(true);
	};

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setEditForm((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSaveClick = async () => {
		try {
			const response = await fetch(`${apiurl}/monthlydata/`, {
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					id: editingId,
					no_of_working_days: editForm.no_of_working_days,
				}),
			});

			if (!response.ok) {
				throw new Error("Failed to update data");
			}

			const updatedData = monthlyData.map((item) =>
				item.id === editingId
					? { ...item, no_of_working_days: editForm.no_of_working_days }
					: item
			);
			setMonthlyData(updatedData);
			setFilteredData(updatedData);
			setModalVisible(false);
			setEditingId(null);
			setEditForm({ no_of_working_days: "" });
		} catch (error) {
			console.error("Error updating data:", error);
		}
	};

	const currentYear = new Date().getFullYear();
	const years = Array.from({ length: 5 }, (_, i) => currentYear - i);

	const months = [
		{ value: "1", label: "January" },
		{ value: "2", label: "February" },
		{ value: "3", label: "March" },
		{ value: "4", label: "April" },
		{ value: "5", label: "May" },
		{ value: "6", label: "June" },
		{ value: "7", label: "July" },
		{ value: "8", label: "August" },
		{ value: "9", label: "September" },
		{ value: "10", label: "October" },
		{ value: "11", label: "November" },
		{ value: "12", label: "December" },
	];

	const monthMap = Object.fromEntries(
		months.map((month) => [month.value, month.label])
	);

	return (
		<div className="workingdays-container">
			<div className="workingdays-go-back">
				<Button
					type="text"
					onClick={() => navigate(-1)}
					icon={<ArrowLeftOutlined />}
				/>
			</div>
			<h1 className="workingdays-title">Working Days</h1>
			<div className="workingdays-filters">
				<Form layout="vertical" className="workingdays-filter-form">
					<Form.Item label="Year">
						<Select
							value={yearFilter}
							onChange={(value) => handleFilterChange(value, "year")}
							className="workingdays-filter-select">
							<Option value="">All</Option>
							{years.map((year) => (
								<Option key={year} value={year.toString()}>
									{year}
								</Option>
							))}
						</Select>
					</Form.Item>
					<Form.Item label="Month">
						<Select
							value={monthFilter}
							onChange={(value) => handleFilterChange(value, "month")}
							className="workingdays-filter-select"
							disabled={!yearFilter} 
						>
							<Option value="">All</Option>
							{months.map((month) => (
								<Option key={month.value} value={month.value}>
									{month.label}
								</Option>
							))}
						</Select>
					</Form.Item>
				</Form>
			</div>
			<div className="workingdays-card-container">
				{filteredData.map((item) => (
					<Card
						key={item.id}
						title={`Year: ${item.year}, Month: ${monthMap[item.month]}`}
						extra={
							<Button onClick={() => handleEditClick(item)} type="primary">
								Edit
							</Button>
						}
						className="workingdays-card">
						<p>No of Working Days: {item.no_of_working_days}</p>
					</Card>
				))}
			</div>
			<Modal
				title="Edit Monthly Data"
				visible={modalVisible}
				onCancel={() => setModalVisible(false)}
				footer={[
					<Button key="cancel" onClick={() => setModalVisible(false)}>
						Cancel
					</Button>,
					<Button key="save" type="primary" onClick={handleSaveClick}>
						Save
					</Button>,
				]}>
				<Form layout="vertical">
					<Form.Item label="No of Working Days">
						<Input
							type="number"
							name="no_of_working_days"
							value={editForm.no_of_working_days}
							onChange={handleInputChange}
						/>
					</Form.Item>
				</Form>
			</Modal>
		</div>
	);
};

export default MonthlyDataList;
