import React, { useState, useEffect } from "react";
import { Table, message,  Card } from "antd";
import { useAuth } from "../utils/useAuth";
import "./Employee.css";
import { useParams } from "react-router-dom";

const Relationships = () => {
	
	const [initialData, setInitialData] = useState([]);
	
	const { token, apiurl } = useAuth();
	const id2 =useParams();

	useEffect(() => {
		fetchRelationships();
	}, []);

	const fetchRelationships = async () => {
		try {
			const response = await fetch(`${apiurl}/getrelations/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body:JSON.stringify(id2)
			});
			if (!response.ok) {
				throw new Error("Failed to fetch relationships");
			}
			const data = await response.json();
			console.log(data);
			setInitialData(data.data);
		} catch (error) {
			message.error("Error fetching relationships");
		}
	};

	

	const columns = [
		{
			title: "Relation",
			dataIndex: "relationType",
			key: "relationType",
		},
		{
			title: "Name",
			dataIndex: "relationName",
			key: "relationName",
		},

		{
			title: "Contact",
			dataIndex: "relationContact",
			key: "relationContact",
		},
		{
			title: "Age",
			dataIndex: "relationAge",
			key: "relationAge",
		},
		{
			title: "Aadhaar",
			dataIndex: "relationAadhar",
			key: "relationAadhar",
		},
		
	];

	
	return (
		<div className="basic-main">
			<Card
				bordered={false}
				title="Relationship Details"
				>
				<Table
					className="relation-table"
					dataSource={initialData}
					columns={columns}
					rowKey="id"
					bordered
					pagination={false}
					style={{ marginBottom: 20 }}
				/>
			</Card>
			
		</div>
	);
};

export default Relationships;
