import React, { useState } from "react";
import { Button, Form, Input, message } from "antd";
import "./file.css";
import { useAuth } from "../utils/useAuth";
import Loader from "../Loader/Loader";
import logo from "./../../Images/LOGO-10.png";


function ForgotPassword() {
	const [form] = Form.useForm();
	const { apiurl } = useAuth();
	const [loading, setLoading] = useState(false);

	const handleForgotPassword = async (values) => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/forgotpassword/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(values),
			});

			const data = await response.json();
			if (response.ok) {
				setLoading(false);
				message.success(data.success);
				form.resetFields();
			} else {
				setLoading(false);
				message.success(data.error);
			}
		} catch (error) {
			setLoading(false);
			message.error("An error occurred. Please try again.");
		}
	};

	return (
		<div className="login-main">
			{loading ? (
				<Loader />
			) : (
				<div className="login-container">
					<div className="login-grid">
						<div className="left-section">
							<h2>Reset Password</h2>
							<center>
								<p>Please enter your registered mail id.</p>
							</center>
							<Form
								form={form}
								className="login-form"
								layout="vertical"
								initialValues={{
									remember: true,
								}}
								onFinish={handleForgotPassword}
								autoComplete="off">
								<Form.Item
									label="Email ID"
									name="email"
									rules={[
										{
											required: true,
											message: "Please input your username!",
										},
										{
											type: "email",
											message: "Please input your valid Email ID",
										},
									]}>
									<Input className="inpp" />
								</Form.Item>

								<Form.Item className="sub-btn">
									<Button type="primary" htmlType="submit">
										Submit
									</Button>
								</Form.Item>
							</Form>
						</div>
						<div className="right-section">
							<img src={logo} alt="Logo" className="logo" />
							<p className="description">
								Streamlining Organizational Processes for Seamless
								Synchronization.
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}

export default ForgotPassword;
