import React, { useState, useEffect } from "react";
import { useAuth } from "../utils/useAuth";
import {
	Form,
	Button,
	Upload,
	Typography,
	Row,
	Col,
	message as antdMessage,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

const { Text } = Typography;

const EvidenceForm = ({ formId }) => {
	const [files, setFiles] = useState({});
	const [existingFiles, setExistingFiles] = useState({});
	const [loading, setLoading] = useState(false);
	const { apiurl, token } = useAuth();

	useEffect(() => {
		const fetchExistingFiles = async () => {
			try {
				const response = await fetch(`${apiurl}/form12bb/${formId}/evidence/`, {
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				});

				if (!response.ok) {
					throw new Error("Failed to fetch existing files.");
				}

				const data = await response.json();
				setExistingFiles(data[0]);
			} catch (err) {
				antdMessage.error(err.message);
			}
		};

		fetchExistingFiles();
	}, [apiurl, formId, token]);

	const handleFileChange = (fieldName, info) => {
		setFiles((prevFiles) => ({
			...prevFiles,
			[fieldName]: info.file,
		}));
	};

	const handleSubmit = async () => {
		setLoading(true);

		const formData = new FormData();
		for (const [key, file] of Object.entries(files)) {
			if (file) {
				formData.append(key, file);
			}
		}

		try {
			const response = await fetch(`${apiurl}/form12bb/${formId}/evidence/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
				},
				body: formData,
			});

			if (!response.ok) {
				throw new Error("Network response was not ok");
			}

			const data = await response.json();
			if (response.status === 201) {
				antdMessage.success(data.message);
				setExistingFiles(data.updatedFiles);
			} else {
				antdMessage.error(data.error || "An error occurred");
			}
		} catch (err) {
			antdMessage.error(err.message);
		} finally {
			setLoading(false);
		}
	};

	const fields = [
		"hra",
		"leave_travel_consession",
		"interest_paid",
		"section_80c",
		"section_80ccc",
		"section_80ccd",
		"section_80d",
		"section_80e_interest_paid",
		"section_80g_donation_amount",
		"section_80tta_interest_earned",
	];

	return (
		<div className="evidence-form-container">
			<Form layout="vertical" onFinish={handleSubmit}>
				<Row gutter={16}>
					{fields.map((fieldName) => (
						<Col span={12} key={fieldName}>
							<Form.Item label={fieldName.replace(/_/g, " ").toUpperCase()}>
								{existingFiles[fieldName] ? (
									<div className="file-info">
										<Text type="secondary">Uploaded: </Text>
										<a
											href={`${apiurl}${existingFiles[fieldName]}`}
											target="_blank"
											rel="noopener noreferrer">
											View File
										</a>
									</div>
								) : (
									<Text type="secondary">No file uploaded</Text>
								)}
								<Upload
									beforeUpload={() => false}
									onChange={(info) => handleFileChange(fieldName, info)}
									showUploadList={false}>
									<Button icon={<UploadOutlined />}>Click to Upload</Button>
								</Upload>
							</Form.Item>
						</Col>
					))}
				</Row>
				<Form.Item>
					<Button type="primary" htmlType="submit" loading={loading}>
						{loading ? "Submitting..." : "Submit"}
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default EvidenceForm;
