import { useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Input, message } from "antd";
import { useNavigate } from "react-router-dom";
import {  Form } from "antd";
import { useAuth } from "../utils/useAuth";
import Loader from "../Loader/Loader";
import logo from "./../../Images/LOGO-10.png";

const ResetPasswordForm = () => {
	const [form] = Form.useForm();
	const { uidb64, token } = useParams();
	const navigate = useNavigate();
	const { apiurl } = useAuth();
	const [loading, setLoading] = useState(false);
	
	const handleSubmit = async (values) => {
		setLoading(true);
		if (values.password !== values.confirmPassword) {
			message.error("Passwords do not match");
			setLoading(false);
			return;
		}

		try {
			const password = values.password;

			const response = await fetch(
				`${apiurl}/resetpassword/${uidb64}/${token}/`,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ password }),
				}
			);

			if (!response.ok) {
				const errorData = await response.json();
				message.error(errorData.message);
				setLoading(false);
			}
			message.success("Reset Successful.! Redirecting you to login");
			form.resetFields();
			setLoading(false);
			setTimeout(() => {
				navigate("/login");
			}, 1500);
		} catch (error) {
			setLoading(false);
			message.error("An error occurred while resetting the password");
		}
	};

	if (loading) {
		return <Loader />;
	}

	return (
		<div className="login-main">
			{loading ? (
				<Loader />
			) : (
				<div className="login-container">
					<div className="login-grid">
						<div className="left-section">
							<h2>Reset Password</h2>
							<Form
								form={form}
								className="login-form"
								layout="vertical"
								initialValues={{
									remember: true,
								}}
								onFinish={handleSubmit}
								autoComplete="off">
								<Form.Item
									label="Password"
									name="password"
									rules={[
										{
											required: true,
											message: "Please input your password!",
										},
									]}>
									<Input.Password className="inpp" />
								</Form.Item>

								<Form.Item
									label="Confirm Password"
									name="confirmPassword"
									rules={[
										{
											required: true,
											message: "Please input your password!",
										},
									]}>
									<Input.Password className="inpp" />
								</Form.Item>

								<Form.Item className="sub-btn">
									<Button type="primary" htmlType="submit">
										Submit
									</Button>
								</Form.Item>
							</Form>
						</div>
						<div className="right-section">
							<img src={logo} alt="Logo" className="logo" />
							<p className="description">
								Streamlining Organizational Processes for Seamless
								Synchronization.
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ResetPasswordForm;
