import React, { useEffect, useState } from "react";
import { useAuth } from "../../utils/useAuth";
import { Modal, Tabs, message } from "antd";
import AddEmployee from "../../Employee/AddEmployee";
import "./Home.css";
import TabPane from "antd/es/tabs/TabPane";
import AddEmpToRole from "../../Pages/AddEmpToRole";
import { useNavigate } from "react-router-dom";

const BUSINESS_OWNER_HOME = () => {
	const { apiurl, token } = useAuth();
	const [empData, setEmpData] = useState(null);
	const [childData, setChildData] = useState(null);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [showEmployeeModal, setShowEmployeeModal] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		fetchEmployeeDetails();
		fetchChild();
	}, []);

	const fetchChild = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchchild/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				console.log(data);
				setChildData(data.data);
			} else {
				message.error("Unable to find child data please try again");
			}
		} catch (error) {
			message.error(error);
		}
	};
	const fetchEmployeeDetails = async () => {
		try {
			const response = await fetch(`${apiurl}/createemployee/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});

			const data = await response.json();

			if (response.ok) {
				setEmpData(data.data);
			} else {
				message.error(data.error);
			}
		} catch (error) {
			message.error("An error occurred while processing your request.");
		}
	};

	return (
		<>
		
			<div className="business-owner-home">
				<h2>Bussiness Owner Functionalities</h2>
				<div className="functionalities">
					<div
						className="action-item"
						onClick={() => setShowEmployeeModal(true)}>
						<div className="action-item-header">Manage HR Head</div>
						<div className="action-item-body">
							Click here to view details or manage HR Head.
						</div>
					</div>
					<div className="action-item" onClick={() => navigate("/employees")}>
						<div className="action-item-header">View Employees</div>
						<div className="action-item-body">
							Click here to view all employees in your organization.
						</div>
					</div>
					<div className="action-item" onClick={() => navigate("/empinorg")}>
						<div className="action-item-header">
							View Employees Monthly Data
						</div>
						<div className="action-item-body">
							Click here to view all the employees and their leave balances
							along with CTC of all the employees in your organization with
							respect to month.
						</div>
					</div>

					<div className="action-item" onClick={() => navigate("/removeip")}>
						<div className="action-item-header">Manage Ip Restriction</div>
						<div className="action-item-body">
							Click here to enable or disable the ip restriction
						</div>
					</div>
					<div
						className="action-item"
						onClick={() => navigate("/optholidaysdata")}>
						<div className="action-item-header">
							Optional Holidays Information
						</div>
						<div className="action-item-body">
							Click here to view the information of employees who took optional
							holidays
						</div>
					</div>
				
				</div>
				<Modal
					className="employee-modal"
					open={showEmployeeModal}
					onCancel={() => {
						fetchChild();
						setShowEmployeeModal(false);
					}}
					footer={null}>
					{childData && (
						<div className="bussiness-owner-det">
							<div className="section-head">HR HEAD</div>

							{childData?.hr && childData?.hrStatus !== "approved" && (
								<div className="assign-bo">
									<div>
										Name: <span>{childData?.hr?.userName}</span>{" "}
										{childData?.hrStatus}
									</div>
								</div>
							)}

							{!childData?.hr && (
								<div className="assign-bo">
									NO HR HEAD{" "}
									<button onClick={() => setIsModalVisible(true)}>
										Click to add
									</button>
								</div>
							)}

							{childData?.hrStatus === "rejected" && (
								<div className="assign-bo">
									NO HR HEAD{" "}
									<button onClick={() => setIsModalVisible(true)}>
										Click to add
									</button>
								</div>
							)}

							{childData?.hr &&
								childData?.hrStatus !== "rejected" &&
								childData?.hrStatus !== "underreview" && (
									<div className="info">
										<div>
											Name: <span>{childData?.hr?.userName || "-"} </span>
										</div>
										<div>
											Contact No:{" "}
											<span>{childData?.hr?.phoneNumber || "-"}</span>
										</div>
										<div>
											Email: <span>{childData?.hr?.email || "-"}</span>
										</div>
										<div>
											Designation:{" "}
											<span>{childData?.hr?.designation || "-"}</span>
										</div>
										<div>
											Department:{" "}
											<span>{childData?.hr?.department || "-"}</span>
										</div>
										<div>
											Type: <span>{childData?.hr?.type || "-"}</span>
										</div>
										<div>
											Gender: <span>{childData?.hr?.gender || "-"}</span>
										</div>
										<div>
											Date Of Birth:{" "}
											<span>{childData?.hr?.dateOfBirth || "-"}</span>
										</div>
									</div>
								)}
						</div>
					)}
				</Modal>
			</div>
			<Modal
				title="Add HR Head"
				open={isModalVisible}
				onCancel={() => {
					fetchChild();
					setIsModalVisible(false);
				}}
				footer={null}>
				<div className="choice-selection">
					<Tabs defaultActiveKey="1" style={{ margin: "10px" }}>
						<TabPane tab="Add Existing" key="1">
							<AddEmpToRole
								role="HR_HEAD"
								child_id={childData?.id}></AddEmpToRole>
						</TabPane>
						<TabPane tab="Add New" key="2">
							<AddEmployee
								role="HR_HEAD"
								child_id={childData?.id}></AddEmployee>
						</TabPane>
					</Tabs>
				</div>
			</Modal>
		</>
	);
};

export default BUSINESS_OWNER_HOME;
