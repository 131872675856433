import React, { useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useAuth } from "../../utils/useAuth";
import { Card, message, Button, Table } from "antd";
import './Home.css';

const EmployeesData = () => {
    const { month, year } = useParams();
    const [employees, setEmployees] = useState([]);
    const { apiurl, token } = useAuth();

    useEffect(() => {
        fetchEmpData();
    }, [month, year]);

    const columns = [
        {
            title: "Name",
            dataIndex: "userName",
            key: "userName",
            render: (text, record) => (
				<>
                <Link to={`/employees/${record.id}`}>
                    {text}
                </Link>
				
				</>
            ),
        },
        {
            title: "Date of Joining",
            dataIndex: "dateOfJoining",
            key: "dateOfJoining",
        },
        {
            title: "Department",
            dataIndex: "department",
            key: "department",
        },
        {
            title: "Designation",
            dataIndex: "designation",
            key: "designation",
        },
        {
            title: "CTC",
            dataIndex: "ctc",
            key: "ctc",
        },
        {
            title: "Salary",
            dataIndex: "salary",
            key: "salary",
        },
        {
            title: "No of Days Present",
            dataIndex: "present",
            key: "present",
        },
        {
            title: "Leaves Taken",
            dataIndex: "leaves",
            key: "leaves",
        },
        
        {
            title: "Absent",
            dataIndex: "absent",
            key: "absent",
        },
        {
            title: "No of latelogins",
            dataIndex: "latelogin",
            key: "latelogin",
        },
        {
            title: "Carry Forwarded Leave Balance",
            dataIndex: "carryleavebal",
            key: "carryleavebal",
        },
        {
            title: "Current Leave Balance",
            dataIndex: "currentleavebal",
            key: "currentleavebal",
        },
       
    ];

    const fetchEmpData = async () => {
        try {
            const response = await fetch(`${apiurl}/empdataforhr/`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify({
                    'childid': sessionStorage.getItem('selectedChildId'),
                    'month': month,
                    'year': year
                })
            });

            const data = await response.json();

            if (response.ok) {
                setEmployees(data);
            } else {
                message.error(data.error);
            }
        } catch (error) {
            message.error("An error occurred while processing your request.");
        }
    };

    return (
        <div className="empdata-table">
            <h1>Employee Data</h1>
            <Table
                dataSource={employees}
                columns={columns}
                rowKey="id" // Add a unique key for each row
            />
        </div>
    );
};

export default EmployeesData;
