import React, { useState, useEffect } from "react";
import { Button, Spin } from "antd";
import { PlusOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import RecentParticipants from "./RecentParticipants";
import ChatWindow from "./ChatWindow";
import NewChatModal from "./NewChatModal";
import { useAuth } from "../utils/useAuth";
import "./chat.css"; // Import the CSS file

const ChatApp = () => {
	const { token, apiurl } = useAuth();
	const [loading, setLoading] = useState(false);
	const [conversations, setConversations] = useState([]);
	const [selectedConversation, setSelectedConversation] = useState(null);
	const [showNewChatModal, setShowNewChatModal] = useState(false);

	useEffect(() => {
		fetchConversations();
	}, [apiurl, token]);

	const fetchConversations = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/conversations/`, {
				method: "GET",
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			if (!response.ok) throw new Error("Failed to fetch conversations");
			const data = await response.json();
			setConversations(data);
		} catch (error) {
			console.error("Error:", error);
		} finally {
			setLoading(false);
		}
	};

	const handleSelectConversation = (conversation) => {
		setSelectedConversation(conversation);
	};

	const handleNewChat = () => {
		setShowNewChatModal(true);
	};

	const handleCloseNewChatModal = () => {
        fetchConversations();
		setShowNewChatModal(false);
	};

	const handleBack = () => {
		window.history.back();
	};

	return (
		<>
			<div className="chat-header">
				<Button
					type="default"
					icon={<ArrowLeftOutlined />}
					onClick={handleBack}
					className="chat-back-button"
				/>
				<div className="chat-title">Messaging</div>
			</div>

			<div className="chat-container">
				<div className="chat-sidebar">
					{loading ? (
						<div className="chat-spinner">
							<Spin size="large" />
						</div>
					) : (
						<RecentParticipants
							selectedConversation={selectedConversation}
							conversations={conversations}
							onSelectConversation={handleSelectConversation}
						/>
					)}
					<Button
						type="primary"
						shape="circle"
						icon={<PlusOutlined />}
						size="large"
						className="chat-new-chat-button"
						onClick={handleNewChat}
					/>
				</div>

				<div className="chat-content">
					<div className="chat-area">
						{selectedConversation ? (
							<ChatWindow
								fetchConversations={fetchConversations} conversation={selectedConversation}
							/>
						) : (
							<div className="chat-empty-state">
								Select a conversation to start chatting
							</div>
						)}
					</div>
				</div>
			</div>

			<NewChatModal
				visible={showNewChatModal}
				onClose={handleCloseNewChatModal}
				onConversationSelect={handleSelectConversation}
				fetchConversations={fetchConversations}
			/>
		</>
	);
};

export default ChatApp;
