import React, { useState } from "react";
import { Button, Input, message } from "antd";
import {  Form } from "antd";
import { useAuth } from "../utils/useAuth";
import Loader from "../Loader/Loader";
import {useNavigate } from "react-router-dom";
import logo from "./../../Images/LOGO-10.png";

const ChangePasswordForm = () => {
	const navigate = useNavigate()
	const [form] = Form.useForm();
	const [loading,setLoading] = useState(false);

	
	const { apiurl, token, handleLogout } = useAuth();

	const handleSubmit = (values) => {
		setLoading(true);
		const currentPassword = values.currentPassword;
		const newPassword = values.newPassword;
		const confirmPassword = values.confirmPassword;

		fetch(`${apiurl}/changepassword/`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
			body: JSON.stringify({
				currentPassword,
				newPassword,
				confirmPassword,
			}),
		})
			.then((response) => response.json())
			.then((data) => {
				if (data.success) {
					message.success("Password changed successfully.");
					navigate('/change/success')
					form.resetFields();
					handleLogout();
					setLoading(false);
				} else {
					setLoading(false);
					message.error(data.message);
				}
				
			})
			.catch((error) => {
				setLoading(false);
				message.error("An error occurred while changing the password.");
			});
	};

	if (loading) {
		return (
				<Loader />
		);
	}

	return (
		<div className="login-main">
			{loading ? (
				<Loader />
			) : (
				<div className="login-container">
					<div className="login-grid">
						<div className="left-section">
							<h2>Change Password</h2>
							<Form
								form={form}
								className="login-form"
								layout="vertical"
								onFinish={handleSubmit}
								autoComplete="off">
								<Form.Item
									label="Current Password"
									name="currentPassword"
									rules={[
										{
											required: true,
											message: "Please input your password!",
										},
									]}>
									<Input.Password className="inpp" />
								</Form.Item>

								<Form.Item
									label="New Password"
									name="newPassword"
									rules={[
										{
											required: true,
											message: "Please input your password!",
										},
									]}>
									<Input.Password className="inpp" />
								</Form.Item>

								<Form.Item
									label="Confirm New Password"
									name="confirmPassword"
									rules={[
										{
											required: true,
											message: "Please input your password!",
										},
									]}>
									<Input.Password className="inpp" />
								</Form.Item>

								<Form.Item className="sub-btn">
									<Button type="primary" htmlType="submit">
										Submit
									</Button>
								</Form.Item>
							</Form>
						</div>
						<div className="right-section">
							<img src={logo} alt="Logo" className="logo" />
							<p className="description">
								Streamlining Organizational Processes for Seamless
								Synchronization.
							</p>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default ChangePasswordForm;
