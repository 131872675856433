import React, { useState, useEffect } from "react";
import { Modal, Select } from "antd";
import { useAuth } from "../utils/useAuth";

const { Option } = Select;

const NewChatModal = ({
	visible,
	onClose,
	onConversationSelect,
}) => {
	const { token, apiurl } = useAuth();
	const [employees, setEmployees] = useState([]);
	const [selectedEmployee, setSelectedEmployee] = useState(null);

	useEffect(() => {
		const fetchEmployees = async () => {
			try {
				const response = await fetch(
					`${apiurl}/conversationemployees/?child_id=${sessionStorage.getItem(
						"selectedChildId"
					)}`,
					{
						method: "GET",
						headers: {
							Authorization: `Bearer ${token}`,
						},
					}
				);

				if (!response.ok) throw new Error("Failed to fetch employees");
				const data = await response.json();
				setEmployees(data);
			} catch (error) {
				console.error("Error:", error);
			}
		};
		fetchEmployees();
	}, [apiurl, token]);

	const handleStartConversation = async () => {
		try {
			const response = await fetch(`${apiurl}/conversations/create/`, {
				method: "POST",
				headers: {
					Authorization: `Bearer ${token}`,
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					participant_2: selectedEmployee,
				}),
			});
			if (!response.ok) throw new Error("Failed to start conversation");
			const newConversation = await response.json();
			onConversationSelect(newConversation);
			onClose();
		} catch (error) {
			console.error("Error:", error);
		}
	};

	return (
		<Modal
			title="Start a New Conversation"
			open={visible}
			onCancel={onClose}
			onOk={handleStartConversation}>
			<Select
				showSearch
				placeholder="Select an employee"
				optionFilterProp="children"
				onChange={(value) => setSelectedEmployee(value)}
				style={{ width: "100%" }}>
				{employees.map((emp) => (
					<Option key={emp.id} value={emp.id}>
						{emp.userName}
					</Option>
				))}
			</Select>
		</Modal>
	);
};

export default NewChatModal;
