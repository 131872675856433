import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import { Card, message, Image, Space } from "antd";
import {
	DownloadOutlined,
	RotateLeftOutlined,
	RotateRightOutlined,
	SwapOutlined,
	UndoOutlined,
	ZoomInOutlined,
	ZoomOutOutlined,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import "./Employee.css";

const Documents = () => {
	const [aadharImage, setAadharImg] = useState(null);
	const [panImage, setPanImage] = useState(null);
	const [offerLetter, setOfferLetter] = useState(null);
	const [pfdeclaration, setpfdeclaration] = useState(null);
	const { token, apiurl } = useAuth();

	const id = useParams();

	useEffect(() => {
		fetchImages();
	}, []);

	const onDownload = (src) => {
		fetch(src)
			.then((response) => response.blob())
			.then((blob) => {
				const url = URL.createObjectURL(new Blob([blob]));
				const link = document.createElement("a");
				link.href = url;
				link.download = "image.png";
				document.body.appendChild(link);
				link.click();
				URL.revokeObjectURL(url);
				link.remove();
			});
	};

	const fetchImages = async () => {
		try {
			const response = await fetch(`${apiurl}/gethandleimages/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify(id),
			});
			if (response.ok) {
				const data = await response.json();
				setAadharImg(data.data.aadhar_image);
				setPanImage(data.data.pan_image);
				setOfferLetter(data.data.offer_letter);
				setpfdeclaration(data.data.pf_declaration);
			} else {
				const data = await response.json();
				message.error(data.error);
			}
		} catch (error) {
			message.error("Error fetching images");
		}
	};

	return (
		<div className="documents-main">
			<Card bordered={false} title="Documents">
				<div className="group">
					<Card
						className="document-item"
						title="PAN Card Image"
						style={{ marginBottom: 16 }}>
						<a
							target="_blank"
							rel="noopener noreferrer"
							href={`${apiurl}${panImage}`}>
							<Image
								height={200}
								width={200}
								src={`${apiurl}${panImage}`}
								alt="pan card"
								preview={{
									toolbarRender: (
										_,
										{
											transform: { scale },
											actions: {
												onFlipY,
												onFlipX,
												onRotateLeft,
												onRotateRight,
												onZoomOut,
												onZoomIn,
												onReset,
											},
										}
									) => (
										<Space size={12} className="toolbar-wrapper">
											<DownloadOutlined onClick={onDownload} />
											<SwapOutlined rotate={90} onClick={onFlipY} />
											<SwapOutlined onClick={onFlipX} />
											<RotateLeftOutlined onClick={onRotateLeft} />
											<RotateRightOutlined onClick={onRotateRight} />
											<ZoomOutOutlined
												disabled={scale === 1}
												onClick={onZoomOut}
											/>
											<ZoomInOutlined
												disabled={scale === 50}
												onClick={onZoomIn}
											/>
											<UndoOutlined onClick={onReset} />
										</Space>
									),
								}}
							/>
						</a>
						{/* <a href={`${apiurl}${panImage}`} download>
							<Button type="primary" style={{ marginTop: 16 }}>
								Download PAN Card
							</Button>
						</a> */}
					</Card>
					<Card className="document-item" title="Aadhaar Card Image">
						<Image
							height={200}
							width={200}
							src={`${apiurl}${aadharImage}`}
							alt="aadhaar card"
							preview={{
								toolbarRender: (
									_,
									{
										transform: { scale },
										actions: {
											onFlipY,
											onFlipX,
											onRotateLeft,
											onRotateRight,
											onZoomOut,
											onZoomIn,
											onReset,
										},
									}
								) => (
									<Space size={12} className="toolbar-wrapper">
										<DownloadOutlined onClick></DownloadOutlined>
										<SwapOutlined rotate={90} onClick={onFlipY} />
										<SwapOutlined onClick={onFlipX} />
										<RotateLeftOutlined onClick={onRotateLeft} />
										<RotateRightOutlined onClick={onRotateRight} />
										<ZoomOutOutlined
											disabled={scale === 1}
											onClick={onZoomOut}
										/>
										<ZoomInOutlined
											disabled={scale === 50}
											onClick={onZoomIn}
										/>
										<UndoOutlined onClick={onReset} />
									</Space>
								),
							}}
						/>
					</Card>
					<Card className="document-item" title="Offer Letter">
						<Image
							height={200}
							width={200}
							src={`${apiurl}${offerLetter}`}
							alt="offer letter"
							preview={{
								toolbarRender: (
									_,
									{
										transform: { scale },
										actions: {
											onFlipY,
											onFlipX,
											onRotateLeft,
											onRotateRight,
											onZoomOut,
											onZoomIn,
											onReset,
										},
									}
								) => (
									<Space size={12} className="toolbar-wrapper">
										<DownloadOutlined onClick={onDownload} />
										<SwapOutlined rotate={90} onClick={onFlipY} />
										<SwapOutlined onClick={onFlipX} />
										<RotateLeftOutlined onClick={onRotateLeft} />
										<RotateRightOutlined onClick={onRotateRight} />
										<ZoomOutOutlined
											disabled={scale === 1}
											onClick={onZoomOut}
										/>
										<ZoomInOutlined
											disabled={scale === 50}
											onClick={onZoomIn}
										/>
										<UndoOutlined onClick={onReset} />
									</Space>
								),
							}}
						/>
					</Card>
					<Card className="document-item" title="PF Declaration">
						<Image
							height={200}
							width={200}
							src={`${apiurl}${pfdeclaration}`}
							alt="Pf Declaration"
							preview={{
								toolbarRender: (
									_,
									{
										transform: { scale },
										actions: {
											onFlipY,
											onFlipX,
											onRotateLeft,
											onRotateRight,
											onZoomOut,
											onZoomIn,
											onReset,
										},
									}
								) => (
									<Space size={12} className="toolbar-wrapper">
										<DownloadOutlined onClick={onDownload} />
										<SwapOutlined rotate={90} onClick={onFlipY} />
										<SwapOutlined onClick={onFlipX} />
										<RotateLeftOutlined onClick={onRotateLeft} />
										<RotateRightOutlined onClick={onRotateRight} />
										<ZoomOutOutlined
											disabled={scale === 1}
											onClick={onZoomOut}
										/>
										<ZoomInOutlined
											disabled={scale === 50}
											onClick={onZoomIn}
										/>
										<UndoOutlined onClick={onReset} />
									</Space>
								),
							}}
						/>
						{/* <a href={`${apiurl}${offerLetter}`} download>
							<Button type="primary" style={{ marginTop: 16 }}>
								Download Offer Letter
							</Button>
						</a> */}
					</Card>
				</div>
			</Card>
		</div>
	);
};

export default Documents;
