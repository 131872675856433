import React, { useState, useEffect } from "react";
import { Input, Button } from "antd";
import { useAuth } from "../utils/useAuth";
import "./chat.css";

const { TextArea } = Input;

const ChatWindow = ({ fetchConversations,conversation }) => {
	console.log(conversation);
	const { token, apiurl } = useAuth();
	const [messages, setMessages] = useState([]);
	const [newMessage, setNewMessage] = useState("");
	const [loading, setLoading] = useState(false); 

	useEffect(() => {
		fetchMessages();
	}, [conversation.id, apiurl, token]);

	const fetchMessages = async () => {
		try {
			const response = await fetch(
				`${apiurl}/conversations/${conversation.id}/messages/`,
				{
					method: "GET",
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			if (!response.ok) throw new Error("Failed to fetch messages");
			const data = await response.json();
			setMessages(data);
		} catch (error) {
			console.error("Error:", error);
		}
	};

	const handleSendMessage = async () => {
		if (!newMessage.trim()) return; 
		setLoading(true); 
		try {
			const response = await fetch(
				`${apiurl}/conversations/${conversation.id}/send/`,
				{
					method: "POST",
					headers: {
						Authorization: `Bearer ${token}`,
						"Content-Type": "application/json",
					},
					body: JSON.stringify({ message: newMessage }),
				}
			);
			if (!response.ok) throw new Error("Failed to send message");
			await response.json();
			fetchMessages();
            fetchConversations();
			setNewMessage(""); 
		} catch (error) {
			console.error("Error:", error);
		} finally {
			setLoading(false); 
		}
	};

	function formatTimestamp(timestamp) {
		const date = new Date(timestamp);
		return date.toLocaleString("en-US", {
			month: "long", 
			day: "numeric",
			year: "numeric", 
			hour: "numeric",
			minute: "numeric", 
			second: "numeric", 
			hour12: true, 
		});
	}

	return (
		<div className="chat-window">
			<div className="chat-messages">
				{messages.map((msg, index) => (
					<div key={index} className="chat-message">
						<div className="chat-message-avatar">{msg.sender.charAt(0)}</div>
						<div className="chat-message-content">
							<div className="chat-message-header">
								<span className="chat-message-sender">{msg.sender}</span>
								<span className="chat-message-timestamp">
									{formatTimestamp(msg.timestamp)}
								</span>
							</div>
							<div className="chat-message-text">{msg.content}</div>
						</div>
					</div>
				))}
			</div>
			<div className="chat-input">
				<TextArea
					rows={4}
					value={newMessage}
					onChange={(e) => setNewMessage(e.target.value)}
					placeholder="Type your message..."
				/>
				<Button
					type="primary"
					onClick={handleSendMessage}
					loading={loading} 
					style={{ marginTop: 10 }}>
					Send
				</Button>
			</div>
		</div>
	);
};

export default ChatWindow;
