import React, { useEffect, useState } from "react";
import { useAuth } from "../utils/useAuth";
import { Button, Table, Input, message, Modal } from "antd";
import "./Employee.css";
import { useNavigate } from "react-router-dom";
import { LeftCircleOutlined } from "@ant-design/icons";
import Main from "../Homepages/Layouts/Main";


const EmployeesData = () => {
	const [employees, setEmployees] = useState([]);
	const [searchText, setSearchText] = useState("");
	const { apiurl, token } = useAuth();
	const navigate = useNavigate();

	useEffect(() => {
		fetchEmployees();
	}, []);

	const fetchEmployees = async () => {
		try {
			const response = await fetch(`${apiurl}/fetchemployees/`, {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify({
					childid: sessionStorage.getItem("selectedChildId"),
				}),
			});
			if (response.ok) {
				const data = await response.json();
				setEmployees(data.data);
			} else {
				message.error("Unable to find Employees. Please try again.");
			}
		} catch (error) {
			message.error(error);
		}
	};

	const handleView = (record) => {
		navigate(`/employees/${record.id}`);
	};


	const handleSearch = (e) => {
		const value = e.target.value.toLowerCase();
		setSearchText(value);
	};

	const filteredEmployees = employees.filter(
		(employee) =>
			employee.userName?.toLowerCase().includes(searchText) ||
			employee.department?.toLowerCase().includes(searchText) ||
			employee.designation?.toLowerCase().includes(searchText)
	);

	const columns = [
		{
			title: "Name",
			dataIndex: "userName",
			key: "userName",
			render: (text) => (text ? text : "-"),
		},
		{
			title: "Department",
			dataIndex: "department",
			key: "department",
			render: (text) => (text ? text : "-"),
		},
		{
			title: "Designation",
			dataIndex: "designation",
			key: "designation",
			render: (text) => (text ? text : "-"),
		},
		{
			title: "Type",
			dataIndex: "type",
			key: "type",
			render: (text) => (text ? text : "-"),
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
			render: (text) => (text ? text : "-"),
		},
		{
			title: "Action",
			key: "action",
			render: (text, record) => (
				<Button onClick={() => handleView(record)}>View</Button>
			),
		},
	];

	return (
		<Main>
			<div className="go-back">
				<Button type="text" onClick={() => navigate(-1)}>
					<LeftCircleOutlined />
					Go back
				</Button>
			</div>
			<div className="employeelist-main">
				<div className="employee-search">
				<div className="section-head">Employees</div>
					<Input
						placeholder="Search employees"
						value={searchText}
						onChange={handleSearch}
					/>
				</div>
				<Table
					className="employee-table"
					dataSource={filteredEmployees}
					columns={columns}
					rowKey="id"
					bordered={false}
					pagination={{ pageSize: 8 }}
				/>
			</div>
		</Main>
	);

};

export default EmployeesData;
