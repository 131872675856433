import React, { useState, useEffect } from "react";
import { Card, Statistic, Modal, Button } from "antd";
import {
	ArrowUpOutlined,
	ArrowDownOutlined,
	ClockCircleOutlined,
	CheckCircleOutlined,
} from "@ant-design/icons";
import { useAuth } from "../utils/useAuth";

const Cmetrics = () => {
	const { apiurl, token } = useAuth();
	const [loading, setLoading] = useState(false);
	const [present, setPresent] = useState(0);
	const [absent, setAbsent] = useState(0);
	const [working, setWorking] = useState(0);
	const [late, setLate] = useState(0);
	const [lateDays, setLateDays] = useState([]);
	const [absentDays, setAbsentDays] = useState([]);
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [isModalVisible1, setIsModalVisible1] = useState(false);
	const [modalContent, setModalContent] = useState([]);
	const [modalTitle, setModalTitle] = useState("");

	const fetchmonthsdata = async () => {
		setLoading(true);
		try {
			const response = await fetch(`${apiurl}/cmetrics/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setPresent(data.present.length);
				setAbsent(data.absent.length);
				setLate(data.late.length);
				setWorking(data.working);
				setLateDays(data.late);

				// Combine leaves and absents with their types
				const combinedDays = [
					...data.absent.map((entry) => ({ date: entry[4], type: "absent" })),
					...data.leave.map((entry) => ({ date: entry[4], type: "leave" })),
				];
				setAbsentDays(combinedDays);
			}
		} catch (error) {
			console.error("Failed to fetch data", error);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		fetchmonthsdata();
	}, []);

	const showModal = (title, content) => {
		setModalTitle(title);
		setModalContent(content);
		setIsModalVisible(true);
	};
	const showModal1 = (title, content) => {
		setModalTitle(title);
		setModalContent(content);
		setIsModalVisible1(true);
	};

	const handleOk = () => {
		setIsModalVisible(false);
	};

	const handleCancel = () => {
		setIsModalVisible(false);
	};
	const handleCancel1 = () => {
		setIsModalVisible1(false);
	};

	return (
		<div className="cmetrics-main">
			<div className="item-head">Current Month's Insights</div>

			<div className="cmetrics-items">
				<div
					className="cmetric-content"
					style={{
						background: "#3f8600",
					}}>
					<Statistic
						title="On Time"
						value={present}
						precision={0}
						valueStyle={{ color: "#fff" }}
						prefix={<ClockCircleOutlined />}
					/>
				</div>

				<div
					className="cmetric-content"
					style={{
						background: "#faad14",
						cursor: "pointer",
					}}
					onClick={() => showModal1("Late Days", lateDays)}>
					<Statistic
						title="Late"
						value={late}
						precision={0}
						valueStyle={{ color: "#fff" }}
						prefix={<ClockCircleOutlined />}
					/>
				</div>

				<div
					className="cmetric-content"
					style={{
						background: "#cf1322",
						cursor: "pointer",
					}}
					onClick={() => showModal("Absent/Leave Days", absentDays)}>
					<Statistic
						title="Absent/Leave"
						value={absentDays.length}
						precision={0}
						valueStyle={{ color: "#fff" }}
						prefix={<ArrowDownOutlined />}
					/>
				</div>

				<div
					className="cmetric-content"
					style={{
						background: "#3f8600",
					}}>
					<Statistic
						title="Working"
						value={working}
						precision={0}
						valueStyle={{ color: "#fff" }}
						prefix={<CheckCircleOutlined />}
					/>
				</div>
				<Modal
					title={modalTitle}
					open={isModalVisible1}
					footer={false}
					onCancel={handleCancel1}>
					<ul>
						{modalContent.map((day, index) => (
							<li key={index}>{day}</li>
						))}
					</ul>
				</Modal>
				<Modal
					title={modalTitle}
					open={isModalVisible}
					footer={false}
					onCancel={handleCancel}>
					<ul>
						{modalContent.map((day, index) => (
							<li key={index}>
								{day.date} ({day.type})
							</li>
						))}
					</ul>
				</Modal>
			</div>
		</div>
	);
};

export default Cmetrics;
