import React, { useEffect, useState } from "react";
import { Layout,  Button, message, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";
import logo from "./../../Images/LOGO-10-black.png";
import { useAuth } from "../utils/useAuth";
import './Style.css';
import userimg from "./../../Images/userimg.jpg";

import {
	UserOutlined,
	LogoutOutlined,
} from "@ant-design/icons";

const { Header } = Layout;
const HeaderComponent = ({ children }) => {
	const navigate = useNavigate();
	const { handleLogout } = useAuth();
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [profileImg, setProfileImg] = useState("");
	const { apiurl, token } = useAuth();

	useEffect(() => {
		fetchImages();
	}, []);

	const fetchImages = async () => {
		try {
			const response = await fetch(`${apiurl}/handleimages/`, {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
			});
			if (response.ok) {
				const data = await response.json();
				setProfileImg(data.data.profile_image);
			} else {
			}
		} catch (error) {}
	};
	
	const Logout = () => {
		handleLogout();
		navigate('/logout')

		message.success("logout successful");
		
	};

	const Navigateprofile = () => {
		navigate("/profile");
	};

	const sc = () => {
		navigate("/switchchild");
	};

	const changepass = () => {
		navigate("/change");
	};

	const items = [
		{
			key: "1",
			label: (
				<Button onClick={Navigateprofile} type="text">
					<UserOutlined /> Profile
				</Button>
			),
		},
		{
			key: "2",
			label: (
				<Button onClick={sc} type="text">
					<UserOutlined /> Switch Child
				</Button>
			),
		},
		{
			key: "3",
			label: (
				<Button onClick={changepass} type="text">
					<UserOutlined /> Change Password
				</Button>
			),
		},
		{
			key: "4",
			label: (
				<Button onClick={() => setIsModalOpen(true)} type="text">
					<LogoutOutlined /> Logout
				</Button>
			),
		},
	];

	return (
		<Layout>
			<Layout>
				<Header className="head">
					<div className="header-logoo">
						<a href="/home">
							<img alt="logo" src={logo}></img>
						</a>
					</div>
					<div className="header-buttons">
						<Dropdown
							menu={{
								items,
							}}
							placement="bottom"
							trigger={["click"]}>
							<img
								src={profileImg ? `${apiurl}${profileImg}` : userimg}
								alt="profile"
							/>
						</Dropdown>
					</div>
				</Header>

				<Modal
					open={isModalOpen}
					onOk={() => {
						Logout();
						setIsModalOpen(false);
					}}
					onCancel={() => setIsModalOpen(false)}>
					<h3>Are you sure to logout?</h3>
				</Modal>
				<div className="header-only-content">{children}</div>
			</Layout>
		</Layout>
	);
};
export default HeaderComponent;
